import { PageTemplateSimple } from '@components';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { Typography } from '@ui';
import { useTranslation } from 'react-i18next';
import { useCodeLabJobListQuery, useCodeLabSourceListQuery } from '../duck/codeLabApi';
import { CodeLabJobList } from '../components/CodeLabJobList';
import { CodeLabModalsController } from '../modals';

export const CodeLabJobsRootPage = () => {
  const { t } = useTranslation('codeLab');
  const { userPermissionsReady } = useStudyPermissions();

  const codeLabJobList = useCodeLabJobListQuery();
  const codeLabSourceListQuery = useCodeLabSourceListQuery();

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        refetch: codeLabJobList.refetch,
      }}
      content={{
        isLoading: codeLabJobList.isLoading && !codeLabJobList.data && userPermissionsReady,
        errorText: t('loadingError'),
        error: codeLabJobList.error || codeLabSourceListQuery.error,
      }}
    >
      <Typography.Paragraph>{t('pageRootDescription')}</Typography.Paragraph>
      <CodeLabJobList
        jobList={codeLabJobList.data?.items}
        sourceList={codeLabSourceListQuery.data}
        loading={codeLabJobList.isFetching || codeLabSourceListQuery.isFetching}
      />
      <CodeLabModalsController />
    </PageTemplateSimple>
  );
};
