import { AuditLogListPage } from '@modules/audit/containers';
import { ModelRootPage } from '@modules/model/containers';
import routes from '@routes';
import { SourceRootPage } from '@modules/source/containers/SourceRootPage';
import { StoreRootPage } from '@modules/stores/containers';
import { JobRootPage, JobEditPage } from '@modules/job/containers';
import { SnapshotRootPage } from '@modules/snapshot/containers/SnapshotRootPage';
import {
  StudyPage,
  StudyPermissionsPage,
  StudyRootPage,
  StudyInstrumentsPage,
  StudyEventDefinitionsPage,
  StudyNonCrfPage,
  CrossStudyPage,
} from '@modules/study/containers';
import { FeatureGate } from '@modules/user/FeatureGate';
import { StudyApp } from '@modules/study/StudyApp';
import { StudyAppRouterProvider } from '@modules/study/StudyAppRouterProvider';
import { CrossViewerDataPage, ViewerDataPage } from '@modules/viewer/containers';
import { ModelConfigurationPage } from '@modules/model/containers';
import { PageNotFound } from '@components';
import { CrossStudyApp } from '@modules/study/CrossStudyApp';
import { GpdipRootPage } from '@modules/gpdip/containers/GpdipRootPage';
import { SnapshotCreatePage } from '@modules/snapshot/containers/SnapshotCreatePage';
import { SnapshotViewPage } from '@modules/snapshot/containers/SnapshotViewPage';
import { DatasetRootPage } from '@modules/dataset/containers';
import { AppRoleGate, UserRoleGate } from '@modules/user/UserRoleGate';
import { CodeLabJobsRootPage } from '@modules/codeLab/containers/CodeLabJobsRootPage';
import { AnalysisPackageDeployments, AnalysisPackageRootPage } from '@modules/library/analysisPackage/containers';
import { AnalysisObjectPscRoot } from '@modules/library/analysisObjects/psc/containers';
import { AnalysisObjectSQLLabRootPage } from '@modules/library/analysisObjects/containers';
import { AnalysisObjectCDRRoot } from '@modules/library/analysisObjects/cdr/containers';
import { AnalysisObjectModelRoot } from '@modules/library/analysisObjects/model/containers';
import { StudyAnalysisObjectsApp } from '@modules/study/StudyAnalysisObjectsApp';
import { CrossStudyAnalysisObjectsApp } from '@modules/study/CrossStudyAnalysisObjectsApp';
import { AnalysisObjectCodeLabRootPage } from '@modules/library/analysisObjects/containers/AnalysisObjectCodeLabRootPage';
import { StackDatasetsRootPage } from '@modules/dataset/containers/StackDatasetsRootPage';
import { RouteObject } from 'react-router-dom';

const appStudyProviderId = 'study-provider';

export const RoutesStudyApp: RouteObject[] = [
  {
    path: routes.app.root.path,
    id: routes.app.root.id,
    element: <StudyRootPage />,
  },
  {
    path: routes.study.root.path,
    id: appStudyProviderId,
    element: <StudyAppRouterProvider />,
    children: [
      {
        path: routes.study.models.root.path,
        id: routes.study.models.root.id,
        element: (
          <UserRoleGate canDataModelRead>
            <ModelRootPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.models.view.path,
        id: routes.study.models.view.id,
        element: (
          <UserRoleGate canDataModelRead>
            <ModelConfigurationPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.dataViewer.path,
        id: routes.study.dataViewer.id,
        element: <ViewerDataPage />,
      },
      {
        path: routes.study.jobs.root.path,
        id: routes.study.jobs.root.id,
        element: (
          <UserRoleGate canImportJobRead>
            <JobRootPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.jobs.view.path,
        id: routes.study.jobs.view.id,
        element: (
          <UserRoleGate canImportJobRead>
            <JobEditPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.snapshots.root.path,
        id: routes.study.snapshots.root.id,
        element: (
          <UserRoleGate canSnapshotsRead>
            <SnapshotRootPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.gpdip.path,
        id: routes.study.gpdip.id,
        element: (
          <FeatureGate hasGPDIP>
            <UserRoleGate canGpdipConfigure>
              <GpdipRootPage />
            </UserRoleGate>
          </FeatureGate>
        ),
      },
      {
        path: routes.study.snapshots.view.path,
        id: routes.study.snapshots.view.id,
        handle: { parentId: routes.study.snapshots.root.id },
        element: (
          <UserRoleGate canSnapshotsRead>
            <SnapshotViewPage />
          </UserRoleGate>
        ),
      },
      {
        path: routes.study.snapshots.create.path,
        id: routes.study.snapshots.create.id,
        handle: { parentId: routes.study.snapshots.root.id },
        element: (
          <UserRoleGate canSnapshotsRead canSnapshotsInsert>
            <SnapshotCreatePage />
          </UserRoleGate>
        ),
      },
      {
        element: (
          <FeatureGate hasDeployment>
            <StudyAnalysisObjectsApp />
          </FeatureGate>
        ),
        children: [
          {
            path: routes.study.analysisObjectsModels.root.path,
            id: routes.study.analysisObjectsModels.root.id,
            handle: { parentId: routes.study.analysisObjects.id },
            element: (
              <UserRoleGate canGlDmAoRead>
                <AnalysisObjectModelRoot />
              </UserRoleGate>
            ),
          },
          {
            path: routes.study.analysisObjectsCdr.root.path,
            id: routes.study.analysisObjectsCdr.root.id,
            handle: { parentId: routes.study.analysisObjects.id },
            element: (
              <UserRoleGate canGlCdrAoRead>
                <AnalysisObjectCDRRoot />
              </UserRoleGate>
            ),
          },
          {
            path: routes.study.analysisObjectsSQLDatasets.root.path,
            id: routes.study.analysisObjectsSQLDatasets.root.id,
            handle: { parentId: routes.study.analysisObjects.id },
            element: (
              <UserRoleGate canGlSqlAoRead>
                <AnalysisObjectSQLLabRootPage />
              </UserRoleGate>
            ),
          },
          {
            path: routes.study.analysisObjectsPsc.root.path,
            id: routes.study.analysisObjectsPsc.root.id,
            handle: { parentId: routes.study.analysisObjects.id },
            element: (
              <UserRoleGate canGlPscAoRead>
                <AnalysisObjectPscRoot />
              </UserRoleGate>
            ),
          },
        ],
      },
      {
        element: <StudyApp />,
        children: [
          {
            path: routes.study.instruments.path,
            id: routes.study.instruments.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyInstrumentsPage />,
          },
          {
            path: routes.study.eventDefinitions.path,
            id: routes.study.eventDefinitions.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyEventDefinitionsPage />,
          },
          {
            path: routes.study.nonCrf.path,
            id: routes.study.nonCrf.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyNonCrfPage />,
          },
          {
            path: routes.study.permissions.path,
            id: routes.study.permissions.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyPermissionsPage />,
          },
          {
            path: routes.study.sources.root.path,
            id: routes.study.sources.root.id,
            handle: { parentId: routes.study.view.id },
            element: <SourceRootPage />,
          },
          {
            path: routes.study.stores.root.path,
            id: routes.study.stores.root.id,
            handle: { parentId: routes.study.view.id },
            element: <StoreRootPage />,
          },
          {
            path: routes.study.dataset.path,
            id: routes.study.dataset.id,
            handle: { parentId: routes.study.view.id },
            element: <DatasetRootPage />,
          },
          {
            path: routes.study.auditLogs.path,
            id: routes.study.auditLogs.id,
            handle: { parentId: routes.study.view.id },
            element: (
              <UserRoleGate canAuditLogRead>
                <AuditLogListPage />
              </UserRoleGate>
            ),
          },
          {
            path: routes.study.analysisPackage.path,
            id: routes.study.analysisPackage.id,
            handle: { parentId: routes.study.view.id },
            element: (
              <FeatureGate hasDeployment>
                <UserRoleGate canGlApRead>
                  <AnalysisPackageRootPage />
                </UserRoleGate>
              </FeatureGate>
            ),
          },
          {
            path: routes.study.deploymentPlans.path,
            id: routes.study.deploymentPlans.id,
            handle: { parentId: routes.study.view.id },
            element: (
              <FeatureGate hasDeployment>
                <UserRoleGate canGlApRead>
                  <AnalysisPackageDeployments />
                </UserRoleGate>
              </FeatureGate>
            ),
          },
          {
            path: routes.study.view.path,
            id: routes.study.view.id,
            handle: { parentId: routes.study.view.id },
            element: <StudyPage />,
          },
          {
            path: ':studyId/*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];

export const RoutesCrossStudyApp: RouteObject[] = [
  {
    path: routes.crossStudy.root.path,
    id: routes.crossStudy.root.id,
    element: <StudyAppRouterProvider />,
    children: [
      {
        path: routes.crossStudy.models.root.path,
        id: routes.crossStudy.models.root.id,
        element: (
          <AppRoleGate canCrossSetupRead canCrossDataModelRead>
            <ModelRootPage />
          </AppRoleGate>
        ),
      },
      {
        path: routes.crossStudy.models.view.path,
        id: routes.crossStudy.models.view.id,
        element: (
          <AppRoleGate canCrossSetupRead canCrossDataModelRead>
            <ModelConfigurationPage />
          </AppRoleGate>
        ),
      },
      {
        path: routes.crossStudy.dataViewer.path,
        id: routes.crossStudy.dataViewer.id,
        element: (
          <AppRoleGate canCrossSetupRead>
            <CrossViewerDataPage />
          </AppRoleGate>
        ),
      },
      {
        path: routes.crossStudy.jobs.root.path,
        id: routes.crossStudy.jobs.root.id,
        element: (
          <AppRoleGate canCrossSetupRead>
            <JobRootPage />
          </AppRoleGate>
        ),
      },
      {
        path: routes.crossStudy.jobs.view.path,
        id: routes.crossStudy.jobs.view.id,
        handle: { parentId: routes.crossStudy.jobs.root.id },
        element: (
          <AppRoleGate canCrossSetupRead>
            <JobEditPage />
          </AppRoleGate>
        ),
      },
      {
        element: (
          <FeatureGate hasDeployment>
            <CrossStudyAnalysisObjectsApp />
          </FeatureGate>
        ),
        children: [
          {
            path: routes.crossStudy.analysisObjectsModels.root.path,
            id: routes.crossStudy.analysisObjectsModels.root.id,
            handle: { parentId: routes.crossStudy.analysisObjects.id },
            element: (
              <AppRoleGate canCrossDmAoRead>
                <AnalysisObjectModelRoot />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.analysisObjectsCodeLab.root.path,
            id: routes.crossStudy.analysisObjectsCodeLab.root.id,
            handle: { parentId: routes.crossStudy.analysisObjects.id },
            element: (
              <AppRoleGate canCrossClAoRead>
                <AnalysisObjectCodeLabRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.analysisObjectsSQLDatasets.root.path,
            id: routes.crossStudy.analysisObjectsSQLDatasets.root.id,
            handle: { parentId: routes.crossStudy.analysisObjects.id },
            element: (
              <AppRoleGate canCrossSqlAoRead>
                <AnalysisObjectSQLLabRootPage />
              </AppRoleGate>
            ),
          },
        ],
      },
      {
        element: <CrossStudyApp />,
        children: [
          {
            path: routes.crossStudy.sources.root.path,
            id: routes.crossStudy.sources.root.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossSetupRead>
                <SourceRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.stores.root.path,
            id: routes.crossStudy.stores.root.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossSetupRead>
                <StoreRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.dataset.path,
            id: routes.crossStudy.dataset.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossSetupRead>
                <DatasetRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.stackDataset.path,
            id: routes.crossStudy.stackDataset.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossSetupRead>
                <StackDatasetsRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.codeLab.path,
            id: routes.crossStudy.codeLab.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossClSchedulerRead canCrossSetupRead>
                <CodeLabJobsRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.auditLogs.path,
            id: routes.crossStudy.auditLogs.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossSetupRead canCrossAuditLogRead>
                <AuditLogListPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.view.path,
            id: routes.crossStudy.view.id,
            handle: { parentId: routes.crossStudy.root.id },
            element: (
              <AppRoleGate canCrossSetupRead>
                <CrossStudyPage />
              </AppRoleGate>
            ),
          },
          {
            path: routes.crossStudy.analysisPackage.path,
            id: routes.crossStudy.analysisPackage.id,
            handle: { parentId: routes.crossStudy.view.id },
            element: (
              <FeatureGate hasDeployment>
                <AppRoleGate canCrossApRead>
                  <AnalysisPackageRootPage />
                </AppRoleGate>
              </FeatureGate>
            ),
          },
          {
            path: routes.crossStudy.deploymentPlans.path,
            id: routes.crossStudy.deploymentPlans.id,
            handle: { parentId: routes.crossStudy.view.id },
            element: (
              <FeatureGate hasDeployment>
                <AppRoleGate canCrossApRead>
                  <AnalysisPackageDeployments />
                </AppRoleGate>
              </FeatureGate>
            ),
          },
          {
            path: ':studyId/*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];
