import {
  Beaker,
  CameraIcon,
  DataViewerIcon,
  DiagramIcon,
  DnaPlatform,
  ExportIcon,
  ImportIcon,
  SettingIcon,
} from '@components/icons';
import { getFormattedDnaPlatformUrl } from '@routes/utils';
import routes from '@routes';
import { TAuthAppPermissions, TAuthStudyRolesPermissions } from '@modules/auth/AuthTypes';
import { useFeatures } from '@modules/user/duck/userHooks';
import { AnalysisIcon } from '@components/icons/AnalysisIcon';
import { CSSProperties, ReactNode } from 'react';

export const appMenu = (appPermissions: Partial<TAuthAppPermissions>): MenuType => {
  return [
    {
      path: getFormattedDnaPlatformUrl(),
      key: 'DnA-Platform',
      title: 'DNA Platform',
      icon: <DnaPlatform color="grey" />,
    },
    {
      path: routes.app.root.path,
      key: routes.app.root.id,
      title: 'Studies',
      icon: <Beaker color="grey" />,
    },
    {
      type: 'divider',
      key: 'divider-library',
    },
    appPermissions.canCrossSetupRead && {
      path: routes.crossStudy.view.resolver(),
      key: routes.crossStudy.root.id,
      title: 'Setup',
      icon: <SettingIcon color="grey" />,
    },
  ].filter((item) => typeof item === 'object') as MenuType;
};

export const studyMenu = (
  studyId: number,
  userStudyPermissions: Partial<TAuthStudyRolesPermissions>,
  features: ReturnType<typeof useFeatures>,
): MenuType => {
  const { hasGPDIP, hasDeployment } = features;

  const redirectRouteMatrix = [
    userStudyPermissions.canGlDmAoRead && routes.study.analysisObjectsModels.root.resolver({ studyId }),
    userStudyPermissions.canGlCdrAoRead && routes.study.analysisObjectsCdr.root.resolver({ studyId }),
    userStudyPermissions.canGlSqlAoRead && routes.study.analysisObjectsSQLDatasets.root.resolver({ studyId }),
    userStudyPermissions.canGlPscAoRead && routes.study.analysisObjectsPsc.root.resolver({ studyId }),
  ].filter((item) => typeof item === 'string');

  const items = [
    {
      path: routes.study.view.resolver({ studyId }),
      key: routes.study.view.id,
      children: studyInternalMenu(studyId, userStudyPermissions, features),
      title: 'Study',
      icon: <Beaker />,
    },
    hasDeployment &&
      redirectRouteMatrix.at(0) && {
        path: redirectRouteMatrix.at(0),
        key: routes.study.analysisObjects.id,
        children: studyAnalysisObjectsInternalMenu(studyId, userStudyPermissions),
        title: 'Analysis Objects',
        icon: <AnalysisIcon />,
      },
    userStudyPermissions.canDataModelRead && {
      path: routes.study.models.root.resolver({ studyId }),
      key: routes.study.models.root.id,
      title: 'Data Models',
      icon: <DiagramIcon />,
    },
    {
      path: routes.study.dataViewer.resolver({ studyId }),
      key: routes.study.dataViewer.id,
      title: 'Data Viewer',
      icon: <DataViewerIcon />,
    },
    userStudyPermissions.canImportJobRead && {
      path: routes.study.jobs.root.resolver({ studyId }),
      key: routes.study.jobs.root.id,
      title: 'Import',
      icon: <ImportIcon />,
    },
    userStudyPermissions.canSnapshotsRead && {
      path: routes.study.snapshots.root.resolver({ studyId }),
      key: routes.study.snapshots.root.id,
      title: 'Snapshot',
      icon: <CameraIcon />,
    },
    userStudyPermissions.canGpdipConfigure &&
      hasGPDIP && {
        path: routes.study.gpdip.resolver({ studyId }),
        key: routes.study.gpdip.id,
        title: 'Export',
        icon: <ExportIcon />,
      },
  ];
  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const crossStudyMenu = (
  appPermissions: Partial<TAuthAppPermissions>,
  features: ReturnType<typeof useFeatures>,
): MenuType => {
  const { hasDeployment } = features;

  const redirectRouteMatrix = [
    appPermissions.canCrossDmAoRead && routes.crossStudy.analysisObjectsModels.root.resolver(),
    appPermissions.canCrossClAoRead && routes.crossStudy.analysisObjectsCodeLab.root.resolver(),
    appPermissions.canCrossSqlAoRead && routes.crossStudy.analysisObjectsSQLDatasets.root.resolver(),
  ].filter((item) => typeof item === 'string');

  const items = [
    {
      path: routes.crossStudy.view.resolver(),
      key: routes.crossStudy.view.id,
      children: crossStudyInternalMenu(appPermissions, features),
      title: 'Study',
      icon: <Beaker />,
    },
    hasDeployment &&
      redirectRouteMatrix.at(0) && {
        path: redirectRouteMatrix.at(0),
        key: routes.crossStudy.analysisObjects.id,
        children: crossStudyAnalysisObjectsInternalMenu(appPermissions),
        title: 'Analysis Objects',
        icon: <AnalysisIcon />,
      },
    appPermissions.canCrossDmAoRead && {
      path: routes.crossStudy.models.root.resolver(),
      key: routes.crossStudy.models.root.id,
      title: 'Data Models',
      icon: <DiagramIcon />,
    },
    {
      path: routes.crossStudy.dataViewer.resolver(),
      key: routes.crossStudy.dataViewer.id,
      title: 'Data Viewer',
      icon: <DataViewerIcon />,
    },
    appPermissions.canCrossRefTableUpload && {
      path: routes.crossStudy.jobs.root.resolver(),
      key: routes.crossStudy.jobs.root.id,
      title: 'Import',
      icon: <ImportIcon />,
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const studyInternalMenu = (
  studyId: number,
  userStudyPermissions: Partial<TAuthStudyRolesPermissions>,
  features: ReturnType<typeof useFeatures>,
): MenuType => {
  const { hasDeployment } = features;

  const items = [
    {
      path: routes.study.view.resolver({ studyId }),
      key: routes.study.view.id,
      title: 'Details',
    },
    // {
    //   path: routes.study.permissions.resolver({ studyId }),
    //   key: routes.study.permissions.id,
    //   title: 'Permissions',
    // },
    // {
    //   path: routes.study.instruments.resolver({ studyId }),
    //   key: routes.study.instruments.id,
    //   title: 'Instruments',
    // },
    // {
    //   path: routes.study.eventDefinitions.resolver({ studyId }),
    //   key: routes.study.eventDefinitions.id,
    //   title: 'Event-Definitions',
    // },
    // {
    //   path: routes.study.nonCrf.resolver({ studyId }),
    //   key: routes.study.nonCrf.id,
    //   title: 'Non-CRF',
    // },
    {
      path: routes.study.sources.root.resolver({ studyId }),
      key: routes.study.sources.root.id,
      title: 'Data Sources',
    },
    {
      path: routes.study.stores.root.resolver({ studyId }),
      key: routes.study.stores.root.id,
      title: 'Data Stores',
    },
    {
      path: routes.study.dataset.resolver({ studyId }),
      key: routes.study.dataset.id,
      title: 'Datasets',
    },
    hasDeployment &&
      userStudyPermissions.canGlApRead && {
        path: routes.study.analysisPackage.resolver({ studyId }),
        key: routes.study.analysisPackage.id,
        title: 'Analysis Package Versions',
      },
    hasDeployment &&
      userStudyPermissions.canGlApRead && {
        path: routes.study.deploymentPlans.resolver({ studyId }),
        key: routes.study.deploymentPlans.id,
        title: 'Deployment Plans',
      },
    userStudyPermissions.canAuditLogRead && {
      path: routes.study.auditLogs.resolver({ studyId }),
      key: routes.study.auditLogs.id,
      title: 'Audit Log',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const crossStudyInternalMenu = (
  appPermissions: Partial<TAuthAppPermissions>,
  features: ReturnType<typeof useFeatures>,
): MenuType => {
  const { hasDeployment } = features;

  const items = [
    {
      path: routes.crossStudy.view.resolver(),
      key: routes.crossStudy.view.id,
      title: 'Details',
    },
    {
      path: routes.crossStudy.sources.root.resolver(),
      key: routes.crossStudy.sources.root.id,
      title: 'Data Sources',
    },
    {
      path: routes.crossStudy.stores.root.resolver(),
      key: routes.crossStudy.stores.root.id,
      title: 'Data Stores',
    },
    {
      path: routes.crossStudy.dataset.resolver(),
      key: routes.crossStudy.dataset.id,
      title: 'Datasets',
    },
    {
      path: routes.crossStudy.stackDataset.resolver(),
      key: routes.crossStudy.stackDataset.id,
      title: 'Stack Datasets',
    },
    appPermissions.canCrossClSchedulerRead && {
      path: routes.crossStudy.codeLab.resolver(),
      key: routes.crossStudy.codeLab.id,
      title: 'Code Lab',
    },
    hasDeployment &&
      appPermissions.canCrossApRead && {
        path: routes.crossStudy.analysisPackage.resolver(),
        key: routes.crossStudy.analysisPackage.id,
        title: 'Analysis Package Versions',
      },
    hasDeployment &&
      appPermissions.canCrossApRead && {
        path: routes.crossStudy.deploymentPlans.resolver(),
        key: routes.crossStudy.deploymentPlans.id,
        title: 'Deployment Plans',
      },
    appPermissions.canCrossAuditLogRead && {
      path: routes.crossStudy.auditLogs.resolver(),
      key: routes.crossStudy.auditLogs.id,
      title: 'Audit Log',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const studyAnalysisObjectsInternalMenu = (
  studyId: number,
  userStudyPermissions: Partial<TAuthStudyRolesPermissions>,
): MenuType => {
  const items = [
    userStudyPermissions.canGlDmAoRead && {
      path: routes.study.analysisObjectsModels.root.resolver({ studyId }),
      key: routes.study.analysisObjectsModels.root.id,
      title: 'Data Models',
    },
    userStudyPermissions.canGlCdrAoRead && {
      path: routes.study.analysisObjectsCdr.root.resolver({ studyId }),
      key: routes.study.analysisObjectsCdr.root.id,
      title: 'CDR Reports',
    },
    userStudyPermissions.canGlSqlAoRead && {
      path: routes.study.analysisObjectsSQLDatasets.root.resolver({ studyId }),
      key: routes.study.analysisObjectsSQLDatasets.root.id,
      title: 'SQL Lab',
    },
    userStudyPermissions.canGlPscAoRead && {
      path: routes.study.analysisObjectsPsc.root.resolver({ studyId }),
      key: routes.study.analysisObjectsPsc.root.id,
      title: 'PSC/DPSC Filters',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const crossStudyAnalysisObjectsInternalMenu = (appPermissions: Partial<TAuthAppPermissions>): MenuType => {
  const items = [
    appPermissions.canCrossDmAoRead && {
      path: routes.crossStudy.analysisObjectsModels.root.resolver(),
      key: routes.crossStudy.analysisObjectsModels.root.id,
      title: 'Data Models',
    },
    appPermissions.canCrossSqlAoRead && {
      path: routes.crossStudy.analysisObjectsSQLDatasets.root.resolver(),
      key: routes.crossStudy.analysisObjectsSQLDatasets.root.id,
      title: 'SQL Lab',
    },
    appPermissions.canCrossClAoRead && {
      path: routes.crossStudy.analysisObjectsCodeLab.root.resolver(),
      key: routes.crossStudy.analysisObjectsCodeLab.root.id,
      title: 'Code Lab',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export type MenuType = Array<{
  path?: string;
  key: string;
  title?: string;
  icon?: ReactNode;
  children?: MenuType;
  style?: CSSProperties;
  type?: string;
}>;
